import React, { useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import Style from "./Sokeord.module.css"
import { KontoMetaDataInfo } from "../../app/apiSlice"

type SokeordParam = Pick<KontoMetaDataInfo, "sokeord">
export default function SokeordView({ sokeord }: SokeordParam): React.ReactElement {
  const [searchParam] = useSearchParams()

  const treff = useMemo(() => {
    const sok = searchParam.get("sok")
    if (sok !== null && sok.length > 2) {
      return sokeord
        .filter((word) => {
          const w = word.toLowerCase()
          const s = sok.trim().toLowerCase()
          return w.length > 0 && (w.includes(s.trim()) || w === s)
        })
        .join(", ")
        .split(" ")
    }
    return []
  }, [searchParam])

  if (treff.length === 0) return <></>

  return (
    <p className={Style.sokeord}>
      {treff.map((o, index) => {
        return <em key={`${o}-${index}`}>{o} </em>
      })}
    </p>
  )
}

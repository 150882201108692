import React, { useEffect, useState } from "react"
import { Dropdown, DropdownItem } from "@fiken/components"
import { useLocation, useParams } from "react-router"
import { Org, organisasjonsformer } from "../konto/organisasjonsformer"
import OrgLink from "./OrgLink"

export default function OrgVelger(): React.ReactElement {
  const { pathname } = useLocation()
  const params = useParams()
  const orgs = Object.values(organisasjonsformer)
  const { enk } = organisasjonsformer
  const [selectedOrg, setSelectedOrg] = useState<Org>(enk)

  useEffect(() => {
    const orgForm = orgs.find((o) => o.id === params.orgForm) ?? enk
    setSelectedOrg(orgForm)
  }, [pathname])
  return (
    <Dropdown
      items={orgs}
      header={<>{selectedOrg.navn}</>}
      maxWidth={300}
      render={(callback, items, selectedItemIndex, scrollDirection) => {
        return items.map((org, index) => {
          return (
            <DropdownItem
              isSelected={selectedItemIndex === index}
              scrollDirection={scrollDirection}
              onSelect={() => {
                const bool = false
                callback(bool)
                setSelectedOrg(org)
              }}
              key={org.id}
            >
              <OrgLink org={org} />
            </DropdownItem>
          )
        })
      }}
    />
  )
}
